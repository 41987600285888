<template>
  <div class="home">
    <div class="slider">
      <carousel :items-to-show="1" :autoplay="5000" :wrap-around="true">
        <slide v-for="(item,index) in slider" :key="index">
         <div class="slideItem" :style="'background-image: url('+item.img+')'"></div>
        </slide>
        <template #addons>
          <navigation />
        </template>
      </carousel>
    </div>
    <div class="world-dentistry mb-5 mt--100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-md-6">
            <div class="wd-left-thumb">
              <img src="@/assets/vs3.png" alt="shape3" class="shape3">
              <img :src="corporate.img" :alt="corporate.title">
            </div>
          </div>
          <div class="col-md-6">
            <div class="wd-content">
              <div class="section-title style-two">
                <h2>{{ corporate.title }}</h2>
              </div>
              <div v-html="corporate.desc.substring(0,256)+'...'"></div>

              <router-link class="cBtn learn-more" to="/kurumsal">
                  <span aria-hidden="true" class="circle">
                    <span class="icon arrow"></span>
                  </span>
                <span class="button-text">{{ langContent.corporate }}</span>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-for="(item,index) in production">
      <div v-if="index%2===0" class="help-center-area mt--100">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 d-flex flex-center">
              <div class="hlp-center-content text-right pr-5">
                <h4>{{ item.title }}</h4>
                <p>
                  {{item.desc.substring(0,256)+'...'}}
                </p>
                <router-link class="cBtn learn-more" to="/uretim">
                  <span aria-hidden="true" class="circle">
                    <span class="icon arrow"></span>
                  </span>
                  <span class="button-text">{{ langContent.production }}</span>
                </router-link>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="hlp-center-thumb">
                <img :src="item.img" :alt="item.title">
              </div>
            </div>
          </div>
        </div>
        <img src="@/assets/vs1.png" alt="vector-shape1" class="vector-about">
      </div>
      <div v-else class="help-center-area">
        <div class="container">
          <div class="row">
            <div class="col-lg-6">
              <div class="hlp-center-thumb">
                <img :src="item.img" :alt="item.title">
              </div>
            </div>
            <div class="col-lg-6 d-flex flex-center">
              <div class="hlp-center-content">
                <h4>{{ item.title }}</h4>
                <p>
                  {{item.desc.substring(0.256)+'...'}}
                </p>
                <router-link class="cBtn learn-more" to="/uretim">
                  <span aria-hidden="true" class="circle">
                    <span class="icon arrow"></span>
                  </span>
                  <span class="button-text">{{ langContent.production }}</span>
                </router-link>
              </div>
            </div>
          </div>
        </div>
        <img alt="vector-shape1" class="vector-shape1" src="@/assets/vs1.png">
      </div>
    </template>

    <div class="video-block">
							<div class="video-image">
								<iframe width="100%" height="400" src="https://www.youtube.com/embed/gP7ck5pQiMw?si=iUYWpB4rOuEJ_12a" title="Sezersan Tel" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
							</div>
		</div>

    

    <div class="productCatsArea" :style="'background-image:url('+bgImg+')'">
      <router-link to="/" @mouseover="mouseOver('images/bg1.jpg')">
        <div>
          <span>Delta Plastic</span>
          <h3>Delta Plastik</h3>
        </div>
      </router-link>
      <router-link v-for="item in pvc.slice().reverse()"  :to="'/pvc/'+item.seo" @mouseover="mouseOver(item.img)">
        <div>
          <span>PVC</span>
          <h3>{{ item.name }}</h3>
        </div>
      </router-link>


            <router-link v-for="item in apet.slice().reverse()"  :to="'/apet/'+item.seo" @mouseover="mouseOver(item.img)">
              <div>
                <span>APET</span>
                <h3>{{ item.name }}</h3>
              </div>
            </router-link>

    </div>
  </div>
</template>
<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import axiosCustom from "../../axiosCustom";

export default {
  name: 'App',
  components: {
    Carousel, Slide, Pagination, Navigation
  },
  data(){
    return{
      bgImg:'images/bg1.jpg',
      slider:[],
      corporate:[],
      production:[],
      langContent:[],
      tanitimfilmi:[],
      pvc:[],
      apet:[],
    }
  },
  created() {
    axiosCustom
        .get('langfix')
        .then(response => {
          this.langContent = response.data[0]
          document.title = 'Delta Plastik | '+response.data[0].home
        })
        .catch(error => {
          console.log(error);
        })
      axiosCustom
        .get('tanitimfilmi')
        .then(response => {
          this.tanitimfilmi = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('slider')
        .then(response => {
          this.slider = response.data
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('corporate')
        .then(response => {
          this.corporate = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('production')
        .then(response => {
          this.production = response.data
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('pvc')
        .then(response => {
          this.pvc = response.data.filter(c => c.main == 1)
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('apet')
        .then(response => {
          this.apet = response.data.filter(c => c.main == 1)
        })
        .catch(error => {
          console.log(error);
        })
  },
  methods: {
    mouseOver: function(e){
      this.bgImg = e
    }
  },
  beforeRouteLeave (to, from, next) {
    next();
    window.scrollTo(0, 0);
  },
  beforeRouteEnter (to, from, next) {
    next();
    window.scrollTo(0, 0);
  }
}
</script>
<style>
.slider{
  width: 100vw;
  height: 100vh;
}
.slideItem{
  width: 100vw;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.carousel__prev{
  left: 50px;
}
.carousel__next{
  right: 50px;
}
.productCatsArea{
  display: flex;
  width: 100vw;
  height: 90vh;
  transition: all ease .5s;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
}
.productCatsArea >a{
  flex: 30%;
  width: 100%;
  height: 33.3333333333%;
  background: transparent;
  display: flex;
  position: relative;
  align-items: flex-end;
  border: #fff 1px;
  border-style: solid;
}
.productCatsArea a>div{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
}
.productCatsArea a:after{
  font-family: "FontAwesome";
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 100;
  content: "\f0c1";
  font-size: 50px;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  width: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.15);
  height: 100%;
  padding-top: 0;
  transition: all ease .3s;

}
.productCatsAreaa a:after{
  font-family: "FontAwesome";
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 100;
  content: "\f0c1";
  font-size: 50px;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  width: 100%;
  opacity: 0;
  background: rgba(0, 0, 0, 0.22);
  height: 100%;
  transition: all ease .3s;


}

.productCatsArea a:hover:after{
  padding-top: 100px;
  color: white;

  opacity: 1;

}

.productCatsArea a>div span{
  display: block;
  font-size: 15px;
  font-weight: bold;
  color: #000f00;
}

@media (max-width: 991px){
  .productCatsArea >a{
    flex: 50%;
    width: 100%;
    height: 20%;
  }
}
@media (max-width: 500px){

  .productCatsArea{
    height: 100vh;
  }

  .productCatsArea >a{
    flex: 100%;
    width: 100%;
    height: calc(100% / 9);
  }
  .productCatsArea a:hover:after{
    padding-top: 40px;
    color: white;

    opacity: 1;

  }
}

</style>