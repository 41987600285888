
import {createRouter, createWebHistory} from 'vue-router'
import Home from '../components/views/Home.vue'
const routes = [
  {
    path: '/',
    name: 'Anasayfa',
    component: Home
  },
  {
    path: '/anasayfa',
    name: 'Anasayfa ',
    component: Home
  },
  {
    path: '/kurumsal',
    name: 'Kurumsal',
    component: () => import('../components/views/About.vue')
  },
  {
    path: '/kariyer',
    name: 'Kariyer',
    component: () => import('../components/views/Career.vue')
  },
  {
    path: '/uretim',
    name: 'Üretim',
    component: () => import('../components/views/Production.vue')
  },
  {
    path: '/page/:page',
    name: 'Sayfa',
    component: () => import('../components/views/Page.vue')
  },
  {
    path: '/:s/:c',
    name: 'Ürünler',
    component: () => import('../components/views/Products.vue')
  },
  {
    path: '/:s/:a/:c',
    name: 'Ürünler ',
    component: () => import('../components/views/Products.vue')
  },
  {
    path: '/urun/:c',
    name: 'Ürün ',
    component: () => import('../components/views/ProductsDetail.vue')
  },
  {
    path: '/iletisim',
    name: 'İletişim',
    component: () => import('../components/views/Contact.vue')
  },
  {
    path: '/yonet',
    name: 'Yönet',
    component: () => import('../components/views/Admin.vue'),
    meta: {
      header: 'none'
    }
  }
]

const router = createRouter({
  scrollBehavior(to, from, savedPosition) {
    return {
      top: 0,
    }
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})


router.beforeEach((to, from, next)=>{
  document.title = 'Delta Plastik | '+to.name
  next()
})
export default router

