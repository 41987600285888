<template>
  <div class="footer">
    <footer>
      <div class="footer-top ptb--60">
        <div class="container">
          <div class="row">
            <div class="col-lg-4 col-sm-6">
              <div class="fwidget fwidget-company">
                <div class="flogo"><router-link to="/"><img src="@/assets/logo.svg" alt="logo"></router-link></div>
                <p>
                  {{ langContent.foottext }}
                </p>
              </div>
            </div>
            <div class="col-lg-2 col-sm-6">
              <div class="fwidget fwidget-quicklinks">
                <h4 class="fwidget-title">{{ langContent.menu }}</h4>
                <ul class="links">
                  <li><router-link to="/">{{ langContent.home }}</router-link></li>
                  <li><router-link to="/kurumsal">{{ langContent.corporate }}</router-link></li>
                  <li><router-link to="/uretim">{{ langContent.production }}</router-link></li>
<!--                  <li><router-link to="/kariyer">Kariyer</router-link></li>-->
                      <template v-for="page in pages">
                      <li><router-link :to="'/page/'+page.seo">{{ page.name }}</router-link></li>
                      </template>
                  <li><router-link to="/iletisim">{{ langContent.contact }}</router-link></li>
                </ul>
              </div>
            </div>
            <div class="col-lg-2 col-sm-6">
              <div class="fwidget fwidget-quicklinks">
                <h4 class="fwidget-title">{{ langContent.products }}</h4>
                <ul class="links">
                      <li><router-link :to="'/pvc/'+pvc1">PVC</router-link></li>
                      <li><router-link :to="'/apet/'+apet1">APET</router-link></li>
                </ul>
              </div>
            </div>
            <div class="col-lg-3 col-sm-6">
              <div class="fwidget fwidget-opening-hours">
                <h4 class="fwidget-title">{{ langContent.contact }}</h4>
                <ul class="widget-opening-hours">
                  <li>{{ langContent.phone }}<span><a :href="'tel:'+contact.phone" style="color: #fff">{{ contact.phone }}</a></span></li>
                  <li>{{ langContent.mail }}<span><a :href="'mailto:'+contact.mail" style="color: #fff">{{ contact.mail }}</a></span></li>
                  <li>{{ langContent.addr2 }}<span>{{ contact.addr2 }}</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-bottom-area">
        <div class="container">
          <div class="row">
            <div class="col-md-8">
              <p class="copyright">
                ©{{ langContent.copy }} By <a href="//gifadwork.com" target="_blank">Gif Adw.</a></p>
            </div>
            <div class="col-md-4">
              <ul class="ht-social ft-bottom-sc">
                <li><a target="_blank" :href="contact.twitter"><i class="fa fa-twitter"></i></a></li>
                <li><a target="_blank" :href="contact.facebook"><i class="fa fa-facebook"></i></a></li>
                <li><a target="_blank" :href="contact.instagram"><i class="fa fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
import axiosCustom from "../../axiosCustom";

axiosCustom
export default {
  data() {
    return {
      langContent: [],
      contact: [],
      apet1: [],
      pvc1: [],
      pages:[],
    }
  },
  created() {
    axiosCustom
        .get('langfix')
        .then(response => {
          this.langContent = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('contact')
        .then(response => {
          this.contact = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
      axiosCustom
        .get('pages')
        .then(response => {
          this.pages = response.data
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('pvc')
        .then(response => {
          var rev = response.data.slice().reverse()
          this.pvc1 = rev[0].seo
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('apet')
        .then(response => {
          var rev = response.data.slice().reverse()
          this.apet1 = rev[0].seo
        })
        .catch(error => {
          console.log(error);
        })
  }
}
</script>