<template>
  <div>
    <header id="header" ref="header">
      <div class="header-bottom-wrapper">
        <div class="header-bottom">
          <div class="container">
            <div class="row align-items-center">
              <div class=" col-8 col-lg-3">
                <div class="logo">
                  <router-link to="/"><img alt="logo" src="@/assets/logo.svg"></router-link>
                </div>
              </div>
              <div class="col-lg-9 d-none d-lg-block">
                <div class="main-menu">
                  <nav>
                    <ul id="nav_mobile_menu">
                      <li>
                        <router-link to="/">{{ langContent.home }}</router-link>
                      </li>
                      <li>
                        <router-link to="/kurumsal">{{ langContent.corporate }}</router-link>
                      </li>
                      <li class="has-child"><a href="javascript:void(0)">{{ langContent.products }}</a>
                        <ul class="submenu">
                          <li><router-link :to="'/pvc/'+pvc1">PVC</router-link></li>
                          <li><router-link :to="'/apet/'+apet1">APET</router-link></li>
                        </ul>
                      </li>
<!--                      <li class="has-child"><a href="javascript:void(0)">PVC</a>-->
<!--                        <ul class="submenu">-->
<!--                          <li v-for="item in pvc">-->
<!--                            <router-link :to="'/pvc/'+item.seo">{{ item.name }}</router-link>-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                      </li>-->
<!--                      <li class="has-child"><a href="javascript:void(0)">APET</a>-->
<!--                        <ul class="submenu">-->
<!--                          <li v-for="item in apet">-->
<!--                            <router-link :to="'/apet/'+item.seo">{{ item.name }}</router-link>-->
<!--                          </li>-->
<!--                        </ul>-->
<!--                      </li>-->
                      <li>
                        <router-link to="/uretim">{{ langContent.production }}</router-link>
                      </li>
                      <li>
                        <router-link to="/iletisim">{{ langContent.contact }}</router-link>
                      </li>
                      <li class="has-child">
                        <template v-for="item in langs">
                          <a v-if="getLang == item.kisaltma" href="javascript:void(0)">{{ item.kisaltma }}</a>
                        </template>
                        <ul class="submenu">
                          <template v-for="item in langs">
                            <li v-if="!(getLang == item.kisaltma)" style="cursor: pointer">
                              <a class="nav-link" @click="langChange(item.kisaltma)">{{ item.kisaltma }}</a>
                            </li>
                          </template>
                          <li>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
              <div class="col-4 d-flex justify-content-end  d-lg-none">
                <div class="humburger-btn" @click="isActive=!isActive"><i  v-if="!isActive" class="fa fa-bars"></i> <i v-else class="fa fa-times"></i></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="mobileMenu"  @click="isActive=!isActive"  :class="[isActive ? 'acc' : '']">
     <div>
       <ul id="mobile_menu">
         <li>
           <router-link to="/">{{ langContent.home }}</router-link>
         </li>
         <li>
           <router-link to="/kurumsal">{{ langContent.corporate }}</router-link>
         </li>
         <li>
                     <router-link :to="'/pvc/'+pvc1">PVC</router-link>
         </li>
         <li>
                     <router-link :to="'/apet/'+apet1">APET</router-link>
         </li>
         <li>
           <router-link to="/uretim">{{ langContent.production }}</router-link>
         </li>
         <li>
           <router-link to="/iletisim">{{ langContent.contact }}</router-link>
         </li>
         <li class="has-child">
           <ul>
             <template v-for="item in langs">
               <li v-if="!(getLang == item.kisaltma)" style="cursor: pointer">
                 <a class="nav-link" @click="langChange(item.kisaltma)">{{ item.kisaltma }}</a>
               </li>
             </template>
           </ul>

         </li>
       </ul>
     </div>
    </div>
  </div>
</template>
<script>
import axiosCustom from "../../axiosCustom";

axiosCustom
export default {
  data() {
    return {
      isActive:false,
      langs: [],
      langContent: [],
      apet: [],
      pvc: [],
      apet1: null,
      pvc1: null,
      getLang: '',
    }
  },
  created() {
    this.getLang = localStorage.lang
    axiosCustom.get('diller')
        .then(response => {
          let data = response.data
          for (let key in data) {
            this.langs.push(data[key])
          }
        })
    axiosCustom
        .get('langfix')
        .then(response => {
          this.langContent = response.data[0]
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('pvc')
        .then(response => {
          this.pvc = response.data
          var rev = response.data.slice().reverse()
          this.pvc1 = rev[0].seo
        })
        .catch(error => {
          console.log(error);
        })
    axiosCustom
        .get('apet')
        .then(response => {
          this.apet = response.data
          var rev = response.data.slice().reverse()
          this.apet1 = rev[0].seo
        })
        .catch(error => {
          console.log(error);
        })
  },
  methods: {
    langChange(e) {
      const firstPath = window.location.pathname.split('/')[1];
      if (firstPath == 'urunler' || firstPath == 'urun') {
        localStorage.lang = e
        document.location.href = "/";
      } else {
        localStorage.lang = e
        location.reload(true);
      }
    },
  }
}
</script>
<style scoped>
#header {
  background-color: transparent;
  position: absolute;
  z-index: 5;
  width: 100%;
}

.header-bottom {
  background-color: transparent;
}

.header-bottom.sticky-menu {
  background-color: #fff;
}

.mobileMenu {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0;
  left: -100vw;
  transition: all ease .3s;
  z-index: 2;
}
.mobileMenu.acc {
  display: flex;
  left: 0;
}
.mobileMenu div{
  position: fixed;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.mobileMenu #mobile_menu {
  text-align: center;
  font-size: 24px;
}

.mobileMenu #mobile_menu li {
  margin-bottom: 30px;
}

.mobileMenu #mobile_menu li a {
  font-weight: 700;
}

</style>